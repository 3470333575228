<template>
  <div class="content">
    <div class="top">
      <div class="title">修改密码</div>
      <span>密码为8-16位，须包含数字、字母、符号中至少2种元素</span>
    </div>
    <van-cell-group inset>
      <van-field v-model="phone" :readonly="readonly" />
      <van-field v-model="sms" center clearable placeholder="验证码">
        <template #button>
          <span v-if="smstext" class="yzm" @click="getyzm">{{smstext}}</span>
          <span v-else class="yzm">倒计时{{time}}秒</span>
        </template>
      </van-field>
      <van-field v-model="pass" type="password" placeholder="设置登录密码" />
      <van-field v-model="pass2" type="password" placeholder="再次输入密码" />
    </van-cell-group>
    <div class="but" @click="updatapass">确认修改</div>
  </div>
</template>

<script>
import { sms, updatePassword, resetPassword } from '@/api/user'
import { Toast } from 'vant'
export default {
  data() {
    return {
      readonly: true,
      pass: '',
      pass2: '',
      sms: '',
      phone: '',
      time: 60,
      smstext: '获取验证码'
    }
  },
  created() {
    this.phone = this.$route.query.phone
    if (!this.phone) {
      this.readonly = false
    }
  },
  methods: {
    async updatapass() {
      if (!this.sms) {
        Toast.fail('请填写短信验证码')
      } else if (!this.pass) {
        Toast.fail('请填写密码')
      } else if (!this.pass2) {
        Toast.fail('请再次填写密码')
      } else if (this.pass2 !== this.pass) {
        Toast.fail('两次密码填写不一致')
      } else {
        if (this.readonly) {
          const { data } = await updatePassword({
            password: this.pass,
            confirmPassword: this.pass2,
            verifyCode: this.sms
          })
          if (data.code === 200) {
            Toast.success('修改成功,请重新登录!')
            localStorage.setItem('token', '')
            setTimeout(() => {
              this.$router.push('/login')
            }, 1500)
          } else {
            Toast.fail(data.msg)
          }
        } else {
          const { data } = await resetPassword({
            confirmPassword: this.pass2,
            password: this.pass,
            phone: this.phone,
            verifyCode: this.sms
          })
          if (data.code === 200) {
            Toast.success('修改成功,请重新登录!')
            localStorage.setItem('token', '')
            setTimeout(() => {
              this.$router.push('/login')
            }, 1500)
          } else {
            Toast.fail(data.msg)
          }
        }
      }
    },
    async getyzm() {
      const { data } = await sms({
        phone: this.phone
      })
      if (data.code === 200) {
        this.smstext = ''
        const interval = window.setInterval(() => {
          if (this.time-- <= 0) {
            this.time = 60
            this.smstext = '获取验证码'
            window.clearInterval(interval)
          }
        }, 1000)
      } else {
        Toast.fail(data.msg)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100vh;
  padding-top: 25px;
  box-sizing: border-box;
  .top {
    width: 88%;
    margin-left: 6%;
    margin-bottom: 22px;
    .title {
      font-size: 20px;
      // font-weight: bold;
      color: #424242;
    }
    span {
      font-size: 12px;
      color: #9f9f9f;
    }
  }
  /deep/ .van-cell-group {
    background-color: rgba(0, 0, 0, 0);
    .van-cell {
      background-color: rgba(0, 0, 0, 0);
    }
    .van-field__body {
      line-height: 27px;
    }
    .van-cell::after {
      transform: scaleY(1);
    }
  }
  .yzm {
    color: #f8892f;
  }
  .but {
    border: none;
    outline: none;
    width: 88%;
    margin-left: 6%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #ffffff;
    background-color: #f8892f;
    font-size: 15px;
    border-radius: 25px;
    margin-top: 40px;
  }
}
</style>
